import {usePrinterDataContext} from "../../context/printerDataContext";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import {printerDataType} from "../../types/printerTypes";
import commonStyles from "../../styles/commonStyles.module.css";

export default function PrinterSearch() {
    const {allPrinters, setPrinters} = usePrinterDataContext();

    const handleSearchInput = (e: any) => {
        e.preventDefault();
        const searchValue: string = e.target.value.toLowerCase();

        // Function to filter objects by name containing a particular string
        function filterByName(objects: printerDataType[], searchString: string) {
            return objects.filter(obj => obj.name.toLowerCase().includes(searchString));
        }

        if (searchValue === ''){
            setPrinters(allPrinters);
        }
        else{
            const filteredArray = filterByName(allPrinters, searchValue);
            setPrinters(filteredArray);
        }
    };


    return (
        <TextField
            label="Search by name"
            size="small"
            sx={{width: '40ch', margin: '5pt', height: '30pt'}}
            onChange={handleSearchInput}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton>
                            <SearchIcon/>
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    )
}
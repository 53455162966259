import React, {useState} from 'react';
import DialogWindow from './DialogWindow';
import {useUserContext} from "../../context/userConext";
import {Permissions} from "../../types/authTypes";
import {Button} from "@mui/material";
import {usePrinterDataContext} from "../../context/printerDataContext";
import CreatePrinterForm from "./printerForm/CreatePrinterForm";


function OperationBlock() {
  const {hierarchyData} = usePrinterDataContext();
  const [openAddPrinterDialog, setOpenAddPrinterDialog] = useState<boolean>(false);
  const userInfo = useUserContext();
  const nameCommandMapper = {
    'Install & Configure': 'install',
    'Uninstall': 'uninstall',
    'Configure': 'configure'
  }

  const handleOpenAddPrinterDialog = () => {
    setOpenAddPrinterDialog(true);
  }

  const handleCloseAddPrinterDialog = () => {
    setOpenAddPrinterDialog(false);
  }

  return (
    <>
      {(userInfo.permissions === Permissions.global_admin_permission ||
        userInfo.permissions === Permissions.local_admin_permission) ?
        (<>
          <Button
            variant="outlined"
            sx={{margin: '5pt', height: '30pt'}}
            onClick={handleOpenAddPrinterDialog}
          >Add printer</Button>
          <CreatePrinterForm
            containerHierarchy={hierarchyData}
            isOpen={openAddPrinterDialog}
            onClose={handleCloseAddPrinterDialog}/>
        </>) : null}
      {userInfo.permissions === Permissions.global_admin_permission ?
        <><DialogWindow
          name={"Embedded Client setup"}
          url={'/embedded_clients'}
          operation={nameCommandMapper}
          exampleFile={'embedded_client_example.csv'}
          exampleFileType={'CSV'}
        ></DialogWindow><DialogWindow
          name={"Printer setup"}
          url={'/outputports'}
          exampleFile={'printers_example.csv'}
          exampleFileType={'CSV'}
        ></DialogWindow><DialogWindow
          name={"Containers setup"}
          url={'/container'}
          exampleFile={'container_structure_example.json'}
          exampleFileType={'JSON'}
        ></DialogWindow
        ></> : null}
    </>
  )
}


export default OperationBlock;
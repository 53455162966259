import React from 'react';
import Grid from '@mui/material/Grid';

const Message = (props: {
    message: string
}) => {
  const message = props.message;

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{
        'height': '80vh',
        'color': 'red',
        'fontSize': '20px'
    }}
    >
      <Grid item>
        <p>{message}</p>
      </Grid>
    </Grid>
  );
};

export default Message;

import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from "@mui/material/Button";
import {green, red} from "@mui/material/colors";

interface containerColumn {
  id: 'Name' | 'Serial' | 'Status';
  label: string;
  minWidth?: number;
  maxWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

interface printerColumn {
  id: 'Name' | 'Serial' | 'Status';
  label: string;
  minWidth?: number;
  maxWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}


const containerColumns: readonly containerColumn[] = [
  { id: 'Name', label: 'Name',},
  { id: 'Status', label: 'Status'}
];

const printerColumns: readonly printerColumn[] = [
  { id: 'Name', label: 'Printer Name'},
  { id: 'Status', label: 'Status'}
]

interface ContainerResponse {
  Name: string;
  Status: string;
}

interface PrinterResponse {
  Name: string;
  Serial: string;
  Status: string;
}

function createContainerResponse(
  Name: string,
  Status: string,
): ContainerResponse {
  return {Name, Status};
}

function createPrinterResponse(
  Name: string,
  Serial: string,
  Status: string,
): PrinterResponse {
  return {Name, Serial, Status};
}


export default function StickyHeadTable(props:
{
    tableData: any;
    dataType: string;
}) {
  const tableData = props.tableData;
  const dataType = props.dataType;

  let rows;
  let columns: any;

  if (dataType == "Containers setup"){
    rows = tableData['containers_created'].map(
        (value: string) => createContainerResponse(value, 'success')
    );
    columns = containerColumns;
  }
  else if(dataType == "Printer setup"){
    rows = Object.keys(tableData['eop_response']).map(
        key => {
          const errorCode = tableData['eop_response'][key].errorCode;
          const name = tableData['eop_response'][key].name;
          const status = errorCode === "0" ? "success" : "failed";

          return createPrinterResponse(name, key, status);
        }
    );

    columns = printerColumns;
  }else if(dataType == "Embedded Client setup"){
    rows = tableData["embedded_clients"].map(
        (element: any) => createContainerResponse(element['name'], element['status'])
    );

    columns = containerColumns;
  }


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(3);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <Paper sx={{ width: '96%', overflow: 'hidden', margin: 'auto'}}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column: any) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any) => {
                return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.Name}>
                      <TableCell>{row['Name']}</TableCell>
                      <TableCell style={{ color: red[500] }}>
                        <Button variant="contained"
                                color={row['Status'] === 'Failed' ? "error" : "success"}
                                size="small"
                        >
                          {row['Status']}
                        </Button>
                      </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
      />
    </Paper>
  );
}

import {Box, CircularProgress} from "@mui/material";
import {usePrinterDataContext} from "../../context/printerDataContext";
import * as React from "react";

export default function Loading() {
    const {loadingState} = usePrinterDataContext();

    if (!loadingState){
        return null
    }

    return (
        <Box style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'rgb(240 240 240 / 80%)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1,
        }}>
            <CircularProgress/>
            <p style={{color: '#1976d2'}}>It may take up a minute or two ... </p>
        </Box>
    )
}

import {DragEvent, useState} from 'react';
import * as React from "react";
import {styled} from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export function FileDrop(props: {setFile: any}) {
    const setFile = props.setFile;

    const [isOver, setIsOver] = useState(false);

    // Define the event handlers
    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsOver(true);
    };

    const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsOver(false);
    };

    const captureFile = async (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];

        await setFile(file);
      };

    // @ts-ignore
    const handleUploadFile = file => {
        setFile(file);
    };

    const handleFileEvent = (e: any) => {
        const chosenFile = e.target.files[0]
        e.target.value = ''
        handleUploadFile(chosenFile)
    }

    return (
        <div
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={captureFile}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
                border: '2px dashed',
                borderColor: 'rgb(25 118 210 / 42%)',
                backgroundColor: isOver ? 'lightblue' : 'white',
            }}
        >
            <p> Drag & drop file here or </p>
            <Button component="label" startIcon={<CloudUploadIcon/>}
                    onChange={handleFileEvent}>
                click here
                <VisuallyHiddenInput type="file" accept=".csv,.json"/>
            </Button>
        </div>
    );
}

import { jwtDecode } from "jwt-decode";

class Utils {
    checkIsValidToken = (token: string, cb: () => void) => {
        const decode: {
            exp: number;
        } = jwtDecode(token);
        if (decode.exp < +new Date() / 1000) {
            cb();
        }
    };

    // Transform an array of objects into a CSV string
    arrayToCSV(data: Object[]) {
      // Setup header from object keys
      const header = Object.keys(data[0]).join(",");

      // Setup values from object values
      const values = data.map(item => Object.values(item).join(","));

      // Concat header and values with a linebreak
      return [header, ...values].join("\n");
    };


    getFormattedUTCTime() {
        const date = new Date();

        const formattedDate = date.toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
            timeZone: 'UTC',
            timeZoneName: 'short'
        });
        return formattedDate;
    }

    isIPAddressValid = (ip: string) => {
        const ipPattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        return ipPattern.test(ip);
    }
}

export const utils_instance = new Utils();
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {filteredHistoryResponseDataType} from "../../types/printerTypes";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';

export default function HistoryDialogPopUp(props: {
    tableData: filteredHistoryResponseDataType[] | null;
    openState: boolean;
    setOpen: any;
}) {
    const tableData = props.tableData;
    const open = props.openState;
    const setOpen = props.setOpen;

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = React.useRef<HTMLElement>(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            maxWidth="xl"
        >
            <DialogTitle id="scroll-dialog-title">Printers history</DialogTitle>
            <DialogContent dividers>
                <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                >
                    {
                        tableData
                            ? <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Document Name</TableCell>
                                            <TableCell>User Name</TableCell>
                                            <TableCell>Total Pages</TableCell>
                                            <TableCell>Color Pages</TableCell>
                                            <TableCell>Copies</TableCell>
                                            <TableCell>Paper Size</TableCell>
                                            <TableCell>Date Time</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableData.map((item, index) => (
                                            <React.Fragment key={index}>
                                                <TableRow>
                                                    <TableCell colSpan={9} style={{ textAlign: 'center' }}>
                                                        Port name: <span style={{fontWeight: 'bold'}}>{item.portname}</span>
                                                    </TableCell>
                                                </TableRow>
                                                {item.documents.map((doc, docIndex) => (
                                                    <TableRow key={docIndex}>
                                                        <TableCell
                                                            style={{
                                                                maxWidth: '200px',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                            }}
                                                        >
                                                            {doc.documentName}
                                                        </TableCell>
                                                        <TableCell>{doc.userName}</TableCell>
                                                        <TableCell>{doc.totalPages}</TableCell>
                                                        <TableCell>{doc.colorPages}</TableCell>
                                                        <TableCell>{doc.copies}</TableCell>
                                                        <TableCell>{doc.paperSize}</TableCell>
                                                        <TableCell>{doc.dateTime}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            : 'No data'
                    }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import s from './PrintersFetchingError.module.css'

interface PrintersFetchingErrorProps {
  entity: string;
}

export default function PrintersFetchingError(props: PrintersFetchingErrorProps) {
  const { entity } = props;

  return (
    <Box className={s.processing_error_block}>
      <SentimentVeryDissatisfiedIcon fontSize="large" className={s.processing_error_icon} />
        <Typography id={s.processing_error_text}>
          Oops, something went wrong while fetching {entity}' data. Please try again later.<br/>
        </Typography>
    </Box>
  );
}

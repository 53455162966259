import Box from '@mui/material/Box';
import {CircularProgress, Skeleton} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';

interface TablePlaceholderProps {
  rows: number;
  columns: string[];
}

export default function TablePlaceholder(props: TablePlaceholderProps) {
  const { rows, columns } = props;
  const isMobileLayout = useMediaQuery('(max-width:600px)');
  const mobileLayoutColumns = 3;
  const columnsToShow = isMobileLayout ? columns.slice(0, mobileLayoutColumns) : columns;

  return (
    <Box component="div" position="relative" width="100%" overflow="auto">
      <Table>
        <TableHead>
          <TableRow>
            {columnsToShow.map((column, index) => (
              <TableCell key={index}>
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: rows }).map((_, rowIndex) => (
            <TableRow key={rowIndex}>
              {columnsToShow.map((_, colIndex) => (
                <TableCell key={colIndex}>
                  <Skeleton variant="rectangular" width="100%" height={20} />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
      >
        <CircularProgress />
        <Typography variant="subtitle1" color="#1976d2">
            It may take up a minute or two ...
        </Typography>
      </Box>
    </Box>
  );
}

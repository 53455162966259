import {Box, Button} from "@mui/material";
import {usePrinterDataContext} from "../../context/printerDataContext";
import {ApiInstance} from "../../api/api";
import * as React from "react";
import {useState} from "react";
import GeneralDialogPopUp from "../GeneralDialogPopUp";
import {utils_instance} from "../../utils/Utils";
import {
    Action,
    filteredHistoryResponseDataType,
    historyResponseDataType,
    printerDataType
} from "../../types/printerTypes";
import HistoryDialogPopUp from "../pages/HistoryDialogPopUp";
import DialogTitle from "@mui/material/DialogTitle";
import {styled} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import s from "./printerForm/PrinterForm.module.css";
import TapAndPlayIcon from "@mui/icons-material/TapAndPlay";
import PrintIcon from "@mui/icons-material/Print";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Typography from "@mui/material/Typography";
import DoDisturbOutlinedIcon from '@mui/icons-material/DoDisturbOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import UpdatePrinterForm from "./printerForm/UpdatePrinterForm";

const ActionDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(1),
    },
}));

const buttonStyle = {
  margin: '5pt',
  height: '30pt',
  whiteSpace: 'nowrap',
  padding: '15px 5px',
};

const modalButtonStyle = {
  margin: '10pt',
  height: '50pt',
  whiteSpace: 'nowrap',
  padding: '15px 15px',
  fontSize: '15pt'
}

interface ActionButtonsProps {
  selected: printerDataType[] | undefined;
}

export default function ActionButtons(props: ActionButtonsProps) {
    const {selected} = props;
    const {hierarchyData, setLoadingState, shouldReload, setShouldReload} = usePrinterDataContext();

    const [apiResponse, setApiResponse] = useState<Object | null>(null);
    const [
        apiHistoryResponse,
        setApiHistoryResponse
    ] = useState<filteredHistoryResponseDataType[] | null>(null);

    const [openDialog, setOpenDialog] = useState(false);
    const [openHistoryDialog, setOpenHistoryDialog] = useState(true);
    const [open, setOpen] = useState(false);
    const [openVerification, setVerificationOpen] = useState(false);
    const [actionModalTitle, setActionDialogTitle] = useState<String>('');
    const [classicPrinterAction, setClassicPrinterAction] = useState<Action | null>(null);
    const [mobilePrinterAction, setMobilePrinterAction] = useState<Action | null>(null);
    const [currentAction, setCurrentAction] = useState<Action | null>(null);
    const [newReader, setNewReader] = useState<string>('');
    const [actionName, setActionName] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string | null>('');
    const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);


    const readerLabelMapping: { [key: number]: string } = {
        3982: 'classic',
        4632: 'mobile',
        2717: 'classic',
        3914: 'mobile',
    };

    const openInstallConfigureModal = (actionLabel: String, classic_action: Action, mobile_action: Action) => {
        setOpen(true);
        setActionDialogTitle(actionLabel);
        setClassicPrinterAction(classic_action);
        setMobilePrinterAction(mobile_action);
        setActionName('Install & Configure');
    };

    const openVerificationModal = (currentAction: any, newReader: string) => {
        setVerificationOpen(true);
        setCurrentAction(currentAction);
        setNewReader(newReader);
    };

    const closeInstallConfigureModal = () => {
        setOpen(false);
        setActionDialogTitle('');
        setClassicPrinterAction(null);
        setMobilePrinterAction(null);
    };

    const closeVerificationModal = () => {
        setOpen(false);
        setVerificationOpen(false);
        setActionDialogTitle('');
        setClassicPrinterAction(null);
        setMobilePrinterAction(null);
    };

    const handleUninstallClickOpen = () => {
        setVerificationOpen(true);

        if (Array.isArray(selected) && selected.length !== 0){
            const readerType = selected[0].embeddedConfigurationId;
            const readerValue = readerLabelMapping[readerType];

            setCurrentAction(readerValue === 'classic'  ? Action.UNINSTALL : Action.UNINSTALL_MOBILE);
        }
        setActionName('Uninstall')
    };

    const handleConfigureClickOpen = () => {
        setVerificationOpen(true);

        if (Array.isArray(selected) && selected.length !== 0){
            const readerType = selected[0].embeddedConfigurationId;
            const readerValue = readerLabelMapping[readerType];

            setCurrentAction(readerValue === 'classic'  ? Action.CONFIGURE : Action.CONFIGURE_MOBILE);
        }
        setActionName('Configure')
    };

    const handleAction = async (action: Action | null, current_reader: string) => {
        if (Array.isArray(selected) && selected.length !== 0){
            setOpen(false);
            setVerificationOpen(false);
            setLoadingState(true);

            const updateEmbeddedClient = readerLabelMapping[selected[0].embeddedConfigurationId] !== newReader;
            try {
                const action_result = await ApiInstance.embeddedClient(selected, action, updateEmbeddedClient, newReader)
                editPrinterResponse(action_result)
            } catch (error: any) {
                setApiResponse({'embedded_clients': []});
                if (error.response) {
                    setErrorMessage(error.response.data.detail);
                } else {
                    setErrorMessage('Something went wrong please try again')
                }
            } finally {
                setLoadingState(false); // Stop loading spinner
            }

            setLoadingState(false);
            setOpenDialog(true);
        }
    }

    const handlePrint = async () => {
        if (Array.isArray(selected) && selected.length !== 0) {
            setLoadingState(true);
            const action_result = await ApiInstance.getPrintersHistory(selected)
            editHistoryResponse(action_result)
            setLoadingState(false);
            setOpenHistoryDialog(true);
        }
    }

    const editPrinterResponse = (res: any) => {
        const responseToShow: Object[] = [];

        // @ts-ignore
        selected.forEach(item => {
            const new_el = res.find((item_n: any) => item_n.name === item.name)
            responseToShow.push({'name': new_el.name, 'status': new_el.status});
            item.status = new_el.status;
            item.last_updated = new_el.last_updated;
            item.embeddedConfigurationId = new_el.embeddedConfigurationId;
            item.address = new_el.address;
        });

        setApiResponse({'embedded_clients': responseToShow});
    };

    const editHistoryResponse = (response: historyResponseDataType[]) => {

        const filteredResponse: filteredHistoryResponseDataType[] = response.map(item => ({
            portname: item.portname,
            documents: item.documents.map(doc => ({
                documentName: doc.documentName,
                userName: doc.userName,
                totalPages: doc.totalPages,
                colorPages: doc.colorPages,
                copies: doc.copies,
                paperSize: doc.paperSize,
                dateTime: new Date(doc.dateTime).toLocaleDateString("en-US", {
                    weekday: "short",
                    month: "short",
                    day: "numeric",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                }) ,
            })),
        }));

        setApiHistoryResponse(filteredResponse)
    }

    const handleOpenEditPrinterDialog = () => {
        setOpenEditDialog(true);
    }

    const handleCloseEditPrinterDialog = () => {
        setOpenEditDialog(false);
    }

    let allSameType = selected?.every(item => item.embeddedConfigurationId === selected[0].embeddedConfigurationId);

    return (
        <>
        {apiResponse != null ?
            <GeneralDialogPopUp
                dataType={"Embedded Client setup"}
                tableData={apiResponse}
                openState={openDialog}
                setOpen={setOpenDialog}
                setShouldReload={setShouldReload}
                errorMessage={errorMessage}
            />
            : null
        }
        {apiHistoryResponse &&
            <HistoryDialogPopUp
                tableData={apiHistoryResponse}
                openState={openHistoryDialog}
                setOpen={setOpenHistoryDialog}
            />
        }

        <UpdatePrinterForm containerHierarchy={hierarchyData} initialFormValues={selected} isOpen={openEditDialog} onClose={handleCloseEditPrinterDialog}/>

        <Box sx={{display: 'flex'}}>
            <Button
                variant="outlined"
                sx={{ ...buttonStyle}}
                onClick={handleOpenEditPrinterDialog}
            >Update printer</Button>
            <Button
                variant='outlined'
                sx={{ ...buttonStyle }}
                onClick={() => handlePrint()}
            >Get history</Button>
            <Button
                variant='outlined'
                sx={{ ...buttonStyle }}
                onClick={() => openInstallConfigureModal(
                    'Install & Configure',
                    Action.INSTALL,
                    Action.INSTALL_MOBILE,
                )}
            >Install & Configure</Button>
            <Button
                variant='outlined'
                sx={{ ...buttonStyle }}
                onClick={handleConfigureClickOpen}
            >Configure</Button>
            <Button
                variant='outlined'
                sx={{ ...buttonStyle }}
                onClick={handleUninstallClickOpen}
            >Uninstall</Button>
            { allSameType ? (
                <ActionDialog
                    onClose={closeInstallConfigureModal}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <DialogTitle
                        sx={{m: 0, p: 2}}
                        id="customized-dialog-title"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                    >
                        <SettingsOutlinedIcon fontSize={"medium"} />
                        {actionModalTitle} printer
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={closeInstallConfigureModal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon/>
                    </IconButton>
                    <DialogContent dividers>
                        <Box className={s.modal_item}>
                            <Button
                                variant='outlined'
                                sx={{ ...modalButtonStyle }}
                                onClick={() => openVerificationModal(classicPrinterAction, 'classic')}
                                endIcon={<PrintIcon />}
                            >
                                Classic printer
                            </Button>
                            <Typography
                                sx={{ flex: '1 1 100%' }}
                                color="inherit"
                                variant="body1"
                                component="span"
                            >
                                OR
                            </Typography>
                            <Button
                                variant='outlined'
                                sx={{ ...modalButtonStyle }}
                                onClick={() => openVerificationModal(mobilePrinterAction, 'mobile')}
                                endIcon={<TapAndPlayIcon />}
                            >
                                Mobile enabled
                            </Button>
                        </Box>
                    </DialogContent>
                </ActionDialog>
                ) : (
                <ActionDialog
                    onClose={closeInstallConfigureModal}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <DialogTitle
                        sx={{m: 0, p: 2}}
                        id="customized-dialog-title"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                    >
                        Attention
                        <ErrorOutlineOutlinedIcon fontSize={"medium"} />
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={closeInstallConfigureModal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon/>
                    </IconButton>
                    <DialogContent dividers>
                        <Typography component={'div'} textAlign="center" className={s.verification_message}>
                            Action is only available for devices that have the same type of reader.
                        </Typography>
                        <Typography component={'div'} textAlign="center" className={s.verification_message}>
                            Please choose devices that all have either a <b>classical reader</b> or a <b>mobile reader</b>,
                            but not a mix of both types.
                        </Typography>
                    </DialogContent>
                </ActionDialog>
                )
            }
            { allSameType ? (
                <ActionDialog
                    onClose={closeVerificationModal}
                    aria-labelledby="customized-dialog-title"
                    open={openVerification}
                >
                    <DialogTitle
                        sx={{m: 0, p: 2}}
                        id="customized-dialog-title"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                    >
                        Are you sure you want to '{actionName}'?
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={closeVerificationModal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon/>
                    </IconButton>
                    <DialogContent dividers>
                         <Box className={s.modal_item}>
                             <Box className={s.verification_block}>
                            <Button
                                variant='outlined'
                                sx={{ ...modalButtonStyle }}
                                onClick={() => handleAction(currentAction, '')}
                                id={s.verification_button}
                            >
                                <CheckOutlinedIcon color={"success"} />
                                Yes
                            </Button>
                            <Button
                                variant='outlined'
                                sx={{ ...modalButtonStyle }}
                                onClick={closeVerificationModal}
                                id={s.verification_button}
                            >
                                <DoDisturbOutlinedIcon color={"error"} />
                                No
                            </Button>
                             </Box>
                        </Box>
                    </DialogContent>
                </ActionDialog>
            ) : (
                <ActionDialog
                    onClose={closeVerificationModal}
                    aria-labelledby="customized-dialog-title"
                    open={openVerification}
                >
                    <DialogTitle
                        sx={{m: 0, p: 2}}
                        id="customized-dialog-title"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                    >
                        Attention
                        <ErrorOutlineOutlinedIcon fontSize={"medium"} />
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={closeVerificationModal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon/>
                    </IconButton>
                    <DialogContent dividers>
                        <Typography component={'div'} textAlign="center" className={s.verification_message}>
                            Action is only available for devices that have the same type of reader.
                        </Typography>
                        <Typography component={'div'} textAlign="center" className={s.verification_message}>
                            Please choose devices that all have either a <b>classical reader</b> or a <b>mobile reader</b>,
                            but not a mix of both types.
                        </Typography>
                    </DialogContent>
                </ActionDialog>
                )
            }
        </Box>
    </>
    )
}

type configType = {
    apiUrl: string;
    auth0Domain: string;
    auth0Client: string;
    auth0Audience: string;
};

const config: configType = {
    apiUrl:
        process.env.REACT_APP_API_URL ||
        'https://bldg-tech-printers-service-0.phoenix.dev.wwrk.co/api/',
    auth0Domain:
        process.env.REACT_APP_AUTH0_DOMAIN ||
        'wework-corp-staging.wework-us.auth0.com',
    auth0Client:
        process.env.REACT_APP_AUTH0_CLIENT_ID ||
        '8isFCdXRGzOFtyUJBeT20idcvnS22KKd',
    auth0Audience:
        process.env.REACT_APP_AUTH0_AUDIENCE ||
        'wework',
};

export default config;
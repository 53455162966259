import {usePrinterDataContext} from "../../context/printerDataContext";
import {utils_instance} from "../../utils/Utils";
import Button from "@mui/material/Button";
import commonStyles from "../../styles/commonStyles.module.css";

export default function ExportPrintersButton(){
  const {printers} = usePrinterDataContext();

  const handleDataDownload = (e: any) => {
    e.preventDefault();

    const file = utils_instance.arrayToCSV(printers);

    // Create blob link to download
    const url = window.URL.createObjectURL(
      new Blob([file]),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `printers.csv`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

  }

  return (
      <Button
          sx={{margin: '5pt', height: '30pt'}}
          variant="outlined"
          onClick={handleDataDownload}
      >CSV</Button>
  )
}
import React, {useEffect, useState} from 'react';
import './App.css';
import {useAuth0} from "@auth0/auth0-react";
import MainPage from "./components/pages/RouterPage";
import {CircularProgress} from "@mui/material";
import {ApiInstance} from "./api/api";
import {utils_instance} from "./utils/Utils";
import {UserPublicDataType} from "./types/authTypes";
import { UserContext } from './context/userConext';


const _App: React.FC = () => {
    const {isAuthenticated, user, loginWithRedirect, isLoading, error, getAccessTokenSilently, logout} = useAuth0();

    const [userInfo, setUserInfo] = React.useState<UserPublicDataType | undefined>();
    const [userStatusProgress, setUserStatusProgress] = React.useState(true);

    let token = localStorage.getItem('token')
  
    const getUserInfo = async () => {
        const user = await ApiInstance.getUserInfo();
        setUserInfo(user);
        setUserStatusProgress(false);
    }

    const loadData = async () => {
        if (isAuthenticated) {
            const token = await getAccessTokenSilently()

            localStorage.setItem('token', token)
            ApiInstance.createAxiosInstance(token);

            getUserInfo()
        }

        if (!isAuthenticated && !isLoading) {
            loginWithRedirect()
        }

        if (token) {
          utils_instance.checkIsValidToken(token, loginWithRedirect);
        }
    }

    useEffect(() => {
        loadData()
    }, [isAuthenticated, isLoading, token])


    if ((!token && userStatusProgress) || !userInfo) {
        return (
            <CircularProgress/>
        );
    }

    return (
        <UserContext.Provider value={userInfo}>
            <MainPage />
        </UserContext.Provider>
    )
};


function App() {
    return (
        <_App/>
    );
}

export default App;
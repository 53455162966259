import {printerDataType} from "../../../types/printerTypes";
import {useEffect, useState} from "react";
import * as React from "react";
import {ApiInstance} from "../../../api/api";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import {Box, MenuItem} from "@mui/material";
import s from "./PrinterForm.module.css";
import TextField from "@mui/material/TextField";
import {utils_instance} from "../../../utils/Utils";
import CopyToClipboardButton from "../CopyToClipboardButton";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Message from "./Message";
import {styled} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";

const BootstrapDialog = styled(Dialog)(({theme}) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
}));

export interface UpdateFormProps {
  containerHierarchy: any[];
  initialFormValue: printerDataType | undefined;
  isOpen: boolean;
  onClose: () => void;
}

export default function PrinterForm(props: UpdateFormProps): JSX.Element {
  const fields: string[] = ["region_name", "country_name", "city_name", "building_name", "deviceSerial", "address", "details", "name"]
  const {containerHierarchy, initialFormValue, isOpen, onClose} = props
  const [formValues, setFormValues] = useState({
    id: 0,
    region_name: '',
    country_name: '',
    city_name: '',
    building_name: '',
    deviceSerial: '',
    address: '',
    details: '',
    name: '',
  });
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [message, setMessage] = useState({
    isOpen: false,
    isSuccess: true,
    header: '',
    message: ''
  });

  useEffect(() => {
    if (isOpen && initialFormValue) {
      setFormValues({
        ...initialFormValue,
        details: initialFormValue ? initialFormValue.name.split("__")[1] || '' : '',
      } as typeof formValues);
      setIsEdit(true);
    } else {
      setFormValues({
        id: 0,
        region_name: '',
        country_name: '',
        city_name: '',
        building_name: '',
        deviceSerial: '',
        address: '',
        details: '',
        name: '',
      });
      setIsEdit(false);
    }
  }, [isOpen, initialFormValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {name, value} = e.target;

    setFormValues((prevFormValues) => {
      const updatedValues = {
        ...prevFormValues,
        country_name: name === 'region_name' ? '' : prevFormValues.country_name,
        city_name: name === 'region_name' || name === 'country_name' ? '' : prevFormValues.city_name,
        building_name: name === 'region_name' || name === 'country_name' || name === 'city_name' ? '' : prevFormValues.building_name,
        [name]: value,
      };
      updatedValues.name = `${updatedValues.building_name}_${updatedValues.deviceSerial}--${updatedValues.address}__${updatedValues.details}`;
      return updatedValues;
    });
  };

  const getCountries = () => {
    const region = containerHierarchy.find((r) => r.name === formValues.region_name);
    return region ? region.countries : [];
  };

  const getCities = () => {
    const country = getCountries().find((c: any) => c.name === formValues.country_name);
    return country ? country.cities : [];
  };

  const getBuildings = () => {
    const city = getCities().find((c: any) => c.name === formValues.city_name);
    return city ? city.buildings : [];
  };

  const isFormValid = () => {
    return Object.entries(formValues).every(([key, value]) => {
      if (!fields.includes(key)) {
        return true;
      }
      if (typeof value === 'string') {
        return value.trim() !== '';
      }
      return value !== null && value !== undefined;
    });
  };

  const handleUpdateSubmit = async () => {
    const updateContainer = formValues.building_name !== initialFormValue?.building_name;

    const res = await ApiInstance.updateOutputPort(
      formValues.id,
      formValues.address,
      formValues.deviceSerial,
      formValues.details,
      formValues.building_name,
      updateContainer
    );
    debugger;

    if (res['status']) {
      setMessage({
        isOpen: true,
        isSuccess: true,
        header: "Printer updated successfully! To see the changes, please reload the page.",
        message: "Please make sure that you 'Install & Configure' printer before using it!"
      })
    } else {
      setMessage({
        isOpen: true,
        isSuccess: false,
        header: "Printer not updated!",
        message: "Error occurred - please try again!"
      })
    }
  }

  const handleAddSubmit = async () => {
    const res = await ApiInstance.createOutputPort(
      formValues.address,
      formValues.deviceSerial,
      formValues.details,
      formValues.building_name
    );
    debugger;

    if (res['status']) {
      setMessage({
        isOpen: true,
        isSuccess: true,
        header: "Printer created successfully! To see the changes, please reload the page.",
        message: "Please make sure that you 'Install & Configure' printer before using it!"
      })
    } else {
      setMessage({
        isOpen: true,
        isSuccess: false,
        header: "Printer not updated!",
        message: "Error occurred - please try again!"
      })
    }
  }

  const handleSubmit = async () => {
    if (isEdit) {
      await handleUpdateSubmit()
    } else {
      await handleAddSubmit()
    }
  }

  const onCloseMessage = () => {
    setMessage({isOpen: false, isSuccess: true, header: '', message: ''});
  }

  return (
    <>
      <BootstrapDialog open={isOpen} onClose={onClose} aria-labelledby="customized-dialog-title">
        <DialogTitle sx={{m: 0, p: 2}} id="customized-dialog-title">
          {isEdit ? `Update printer with ID: ${formValues.id}` : "Add printer"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon/>
        </IconButton>
        <DialogContent dividers>
          <Box className={s.form_item} >
            <Box
              className={s.box_item}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{ padding: 2 }}
            >
              <Box>
                <Typography variant="body1">Region:</Typography>
              </Box>
              <Box width="50%">
                <TextField
                  select
                  fullWidth
                  name="region_name"
                  placeholder="Region"
                  value={formValues.region_name}
                  onChange={handleChange}
                  variant="standard"
                  size="small"
                  InputProps={{ readOnly: isEdit }}
                >
                  {containerHierarchy.map((region) => (
                    <MenuItem key={region.name} value={region.name}>
                      {region.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Box>
            <Box
              className={s.box_item}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{ padding: 2 }}
            >
              <Box>
                <Typography variant="body1">Country:</Typography>
              </Box>
              <Box width="50%">
                <TextField
                  select
                  fullWidth
                  name="country_name"
                  value={formValues.country_name}
                  onChange={handleChange}
                  variant="standard"
                  InputProps={{readOnly: isEdit}}
                >
                  {getCountries().map((country: any) => (
                    <MenuItem key={country.name} value={country.name}>
                      {country.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Box>
            <Box
              className={s.box_item}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{ padding: 2 }}
            >
              <Box>
                <Typography variant="body1">City:</Typography>
              </Box>
              <Box width="50%">
                <TextField
                  select
                  fullWidth
                  name="city_name"
                  value={formValues.city_name}
                  onChange={handleChange}
                  variant="standard"
                >
                  {getCities().map((city: any) => (
                    <MenuItem key={city.name} value={city.name}>
                      {city.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Box>
            <Box
              className={s.box_item}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{ padding: 2 }}
            >
              <Box>
                <Typography variant="body1">Building:</Typography>
              </Box>
              <Box width="50%">
                <TextField
                  select
                  fullWidth
                  name="building_name"
                  value={formValues.building_name}
                  onChange={handleChange}
                  variant="standard"
                >
                  {getBuildings().map((building: any) => (
                    <MenuItem key={building} value={building}>
                      {building}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>

            </Box>
            <Box
              className={s.box_item}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{ padding: 2 }}
            >
              <Box>
                <Typography variant="body1">Serial:</Typography>
              </Box>
              <Box width="50%">
                <TextField
                  fullWidth
                  name="deviceSerial"
                  placeholder="Serial"
                  value={formValues.deviceSerial}
                  onChange={handleChange}
                  variant="standard"
                  InputProps={{readOnly: isEdit}}
                  inputProps={{maxLength: 10}}
                />
              </Box>
            </Box>
            <Box
              className={s.box_item}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{ padding: 2 }}
            >
              <Box>
                <Typography variant="body1">IP address:</Typography>
              </Box>
              <Box width="50%">
                <TextField
                  fullWidth
                  name="address"
                  placeholder="IP address"
                  value={formValues.address}
                  onChange={handleChange}
                  variant="standard"
                  error={!!formValues.address && !utils_instance.isIPAddressValid(formValues.address)}
                  helperText={formValues.address && !utils_instance.isIPAddressValid(formValues.address) ? 'Please enter a valid IP address.' : ''}
                />
              </Box>
            </Box>
            <Box
              className={s.box_item}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{ padding: 2 }}
            >
              <Box>
                <Typography variant="body1">Detail:</Typography>
              </Box>
              <Box width="50%">
                <TextField
                  fullWidth
                  name="details"
                  placeholder="Detail"
                  value={formValues.details}
                  onChange={handleChange}
                  variant="standard"
                />
              </Box>
            </Box>
            <Box className={s.box_device_name}>
              {formValues.name}
              <CopyToClipboardButton text={formValues.name}/>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            className={s.form_print_button}
            component="label"
            variant="contained"
            disabled={!isFormValid()}
            onClick={handleSubmit}
          >
            { isEdit ? "Update Printer": "Add Printer" }
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <Message
        isOpen={message.isOpen}
        onClose={onCloseMessage}
        isSuccess={message.isSuccess}
        header={message.header}
        message={message.message}
      />
    </>
  )
}
import {Alert, Snackbar} from "@mui/material";
import s from "./PrinterForm.module.css";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Typography from "@mui/material/Typography";
import * as React from "react";

export interface MessageProps {
  isOpen: boolean;
  onClose: () => void;
  isSuccess: boolean;
  header: string;
  message: string;
}

export default function Message(props: MessageProps) {
  const {isOpen, onClose, isSuccess, header, message} = props
  return (
    <Snackbar
      anchorOrigin={{vertical: "top", horizontal: "center"}}
      autoHideDuration={10000}
      onClose={onClose}
      open={isOpen}
    >
      <Alert icon={false} severity={isSuccess ? "success" : "error"} id={isSuccess ? s.success_alert_popup : s.error_alert_popup}>
        <div className={s.alert_content}>
          <div className={s.alert_icon_section}>
            <CheckCircleOutlineIcon fontSize="large" className={s.alert_icon}/>
          </div>
          <div>
            <Typography>{header}</Typography>
            <Typography variant="body2">{message}</Typography>
          </div>
        </div>
      </Alert>
    </Snackbar>
  )
}
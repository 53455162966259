export enum Permissions {
      global_admin_permission = 'global_admin_permission',
      local_admin_permission = 'local_admin_permission',
      local_user_permission = 'local_user_permission',
      no_permission = 'no_permission'
}

export type UserPublicDataType = {
  user_email: string;
  permissions: Permissions;
};


import PrinterForm from "./PrinterForm";
import React from "react";

export interface CreatePrinterFormProps {
  containerHierarchy: any[];
  isOpen: boolean;
  onClose: () => void;
}

export default function CreatePrinterForm(props: CreatePrinterFormProps): JSX.Element {
  const {containerHierarchy, isOpen, onClose} = props;
  return (
    <PrinterForm
      containerHierarchy={containerHierarchy}
      initialFormValue={undefined}
      isOpen={isOpen}
      onClose={onClose}
    />
  )
}